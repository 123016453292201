import React from 'react'
import Header from './header'

import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle}) => (
  <>
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <div className={styles.content}>{children}</div>
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.siteInfo}>
        <a href='https://www.facebook.com/wildpetfoodcr' target="_blank" title='Faceboock'>
              <img className={styles.logoSocial} src='https://mauspace.com/images_wild/fb.png' alt='FB' /></a>
              <a href='https://www.instagram.com/wildpetfood_cr/' target="_blank" title='Instagram'>
              <img className={styles.logoSocial} src='https://mauspace.com/images_wild/ins.png' alt='Instagram' /></a>
          &copy; {new Date().getFullYear()}, wildpetfood
        </div>
       
        <div className={styles.headerCtaWrapper}>
              <a href='https://wa.me/50684557506' target="_blank"  title='Contact Us'>
              <img className={styles.logoFooter} src='https://mauspace.com/images_wild/WA-Logo.svg' alt='Logo' /></a>
        </div>
      </div>
    </footer>
  </>
)

export default Layout
